import React from 'react'

import '../components/globalStyle.css'
import '../components/404.css'

import Header from '../components/header'
import BackButton from '../components/backButton'


const NotFound = () => (
  <div className= "NotFoundGroup">

    <Header />


    <img className= "notFoundImg" src={require('../img/404.svg')} alt="404image"/>

    <br></br>
    <br></br>

    <h1>Whut.</h1>
    <p>There's nothing here... Turn back!!.</p>

    <br></br>
    <br></br>

    <BackButton />

  </div>


)

export default NotFound
